import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Card, CardContent, Modal } from '@mui/material';
import Papa from 'papaparse';
import { Feedback, Tune, Check, Close , Grading } from '@mui/icons-material';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { useSwipeable } from 'react-swipeable';
import './Cards.css';

window.addEventListener("scroll", (e) => {
  e.preventDefault();
  window.scrollTo(0, 0);
});

const Cards = () => {
  const [questions, setQuestions] = useState([]);
  const [questionsAttempted, setQuestionsAttempted] = useState(0);
  const [questionsCorrect, setQuestionsCorrect] = useState(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showAnswer, setShowAnswer] = useState(false);
  const [wrongAnswers, setWrongAnswers] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState([]);
  const [swipeClass, setSwipeClass] = useState('');
  const [togglew, settogglew] = useState(0);
  const [toggleGradingw,setToggleGrading] = useState(0);
  const [toggleFeedbackw,setToggleFeedback] = useState(0);
  const [review,setReview] = useState(0);
  const [positionX, setPositionX] = useState(0);

  const [positionY, setPositionY] = useState(0);
  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await fetch('https://docs.google.com/spreadsheets/d/e/2PACX-1vQLqgbNKPMb_iA_CNh_N0mhR_2IySESkwTj0P-tH7xGz_q4khf3P2tBCN4Y2fMAUrnEvVjeoQxxUbxS/pub?output=csv');
        const data = await response.text();
        const parsedData = parseCSV(data);
        const rparsedData = shuffleArray(parsedData); // Shuffle the parsed data
        setQuestions(rparsedData);
      } catch (error) {
        console.error('Error fetching and parsing data:', error);
      }
    };
    fetchQuestions();
  }, []);
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };
  const nl2br = (str) => {
    if (typeof str === "undefined") { return; }
    return str.split('\n').map((item, index) => (
      <React.Fragment key={index}>
        {item}
        <br />
      </React.Fragment>
    ));
  };

  const parseCSV = (data) => {
    const parsed = Papa.parse(data, {
      header: true,
      skipEmptyLines: true
    });
    return parsed.data;
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80vw',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: '80vh',
  };

  const handleNext = (value) => {
    setSwipeClass('swipe-left');
  
     if (value === 1) { // Correct answer
      if (wrongAnswers.includes(currentQuestionIndex)) {
        // Remove from wrong answers and do not increment questionsAttempted
        setQuestionsCorrect(questionsCorrect + 1);
        setCorrectAnswers((prev) => [...prev, currentQuestionIndex]);
        setWrongAnswers((prev) => prev.filter((index) => index !== currentQuestionIndex));
      }else if (correctAnswers.includes(currentQuestionIndex)) {
//nothing should happen;
      } else {
        // If not previously wrong, increment both correct and attempted
        setQuestionsAttempted(questionsAttempted + 1);
        setQuestionsCorrect(questionsCorrect + 1);
        setCorrectAnswers((prev) => [...prev, currentQuestionIndex]);
      }
    } else { // Wrong answer
      if (!wrongAnswers.includes(currentQuestionIndex)) {
        if (correctAnswers.includes(currentQuestionIndex)) {
        }else{
        // Add to wrong answers and increment attempted only if not already marked wrong
        setQuestionsAttempted(questionsAttempted + 1);
        setWrongAnswers((prev) => [...prev, currentQuestionIndex]);
      }
      }
    }
    if (review == 1) { // if review mode on.
      if(wrongAnswers.length>0){
        setCurrentQuestionIndex(wrongAnswers[0]);
      }else{
        setReview(false);
        setCurrentQuestionIndex(questionsAttempted);
      }
    }else{
    setCurrentQuestionIndex((prevIndex) => (prevIndex + 1) % questions.length);
  }

  };
const goto = (index) =>{
  toggleGrading();
  setCurrentQuestionIndex(index);
}
  const handlePrevious = () => {
    
    setSwipeClass('swipe-right');
    setShowAnswer(false);
  setCurrentQuestionIndex((prevIndex) => (prevIndex - 1 + questions.length) % questions.length)
    
  };
  const toggle = () => {
    settogglew(!togglew);
  };
  const startReview = () =>{
    if(review==false && wrongAnswers.length>0){
      setReview(true);
       setCurrentQuestionIndex(wrongAnswers[0]); }else{
        setReview(false);
       }
  
  }
  const toggleGrading = () => {
  setToggleGrading(!toggleGradingw)
  }
  const toggleFeedback = () => {
    setToggleFeedback(!toggleFeedbackw)
    }



  const handlers = useSwipeable({
    onSwipedLeft: () => handleNext(0),
    onSwipedRight: () => handlePrevious(),
    onSwiping: (eventData) => {
      console.log(eventData);
      setPositionY(eventData.deltaY); 
      setPositionX(eventData.deltaX); 
      // Example: Apply a dynamic style based on the swipe distance
      const swipeProgress = eventData.absX;  // Absolute distance swiped in pixels
      // Modify the card style or class based on swipe direction and distance
      // This part depends on your implementation details
    },
    onSwiped: (eventData) => {
      //if not next not previous
     // if (eventData.dir !== "Left" && eventData.dir !== "Right") {
        setPositionX(0);
        setPositionY(0);
 //   }
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  if (questions.length === 0) {
    return <Typography>Loading...</Typography>;
  }

  const currentQuestion = questions[currentQuestionIndex];

  return (
    <Box
      {...handlers}
      sx={{
        minHeight: '100vh',
        backgroundColor: '#E0F2FF',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 4
      }}
    >   
     {wrongAnswers.length>0 &&<Button
    variant="contained"
    onClick={startReview}
    sx={{ "position":"absolute", "top":"10px",
      backgroundColor: review ? '#4CAF50' : '#F44336', // Green when review is active, Red otherwise
      color: 'white',
      '&:hover': {
        backgroundColor: review ? '#388E3C' : '#D32F2F', // Darken on hover
      }
    }}
  >
    {review ? 'End Review' : 'Start Review'}
  </Button>}
      <SwitchTransition>
        <CSSTransition
          key={currentQuestionIndex}
          timeout={500}
          classNames={swipeClass}
        >
          <div id="maincard" className="flip-card" style={{ transform: `translateX(${positionX/3}px) translateY(${positionY/3}px)` }}  >
          {wrongAnswers.includes(currentQuestionIndex) && (
    <Button variant="outlined" color="error" startIcon={<Close />} sx={{position:'absolute', zIndex:100, color:'red !important', top: 5}}disabled>
      Incorrect 
    </Button>
  )}
  {correctAnswers.includes(currentQuestionIndex) && (
    <Button variant="outlined" color="error" startIcon={<Check />} sx={{position:'absolute', zIndex:100, color:'green !important', top: 5}}disabled>
      Correct
    </Button>
  )}
            <div className="flip-card-inner" >
              <div className="flip-card-front">
               
                  
                  {togglew ? 
                  (<div class="cross-container"><Typography variant="h1" sx={{ marginTop: 2 }}>{currentQuestion?.word}</Typography></div>

                  ) : (
                  
                    <Typography variant="h5" component="div"> 
                    {nl2br(currentQuestion?.definition || '')}
                  </Typography>
                )}
                
              
              </div>
              <div className="flip-card-back">
              {!togglew ? 
                   (<div class="cross-container"><Typography variant="h1" sx={{ marginTop: 2 }}>{currentQuestion?.word}</Typography></div>

                   )  : (
                  
                    <Typography variant="h5" component="div"> 
                    {nl2br(currentQuestion?.definition || '')}
                  </Typography>
                )}
                
              </div>
            </div>
          </div>
        </CSSTransition>
      </SwitchTransition>
      <Typography variant="body1" sx={{ marginBottom: 2 }}> 
     { /** {currentQuestionIndex + 1} / {questions.length}  */}  
     {questionsAttempted>0 && (<div>{questionsCorrect} / {questionsAttempted} ({parseInt(questionsCorrect/questionsAttempted*100) + "%"})</div>) }
      </Typography>
      <Box sx={{ display: 'flex', gap: 2 }}>
      <Button variant="contained" color="error" onClick={()=>handleNext(0)} sx={{ marginRight: 2 ,textAlign:'center'}} startIcon={<Close />}></Button>
      <Button variant="outline"  sx={{ marginRight: 2 ,textAlign:'center'}} > Q{currentQuestionIndex+1} </Button>
      <Button variant="contained"  color="success" onClick={()=>handleNext(1)} startIcon={<Check />} ></Button>        
      </Box>
    <Modal
      open={toggleGradingw}
      onClose={toggleGrading}
      aria-labelledby="grading-modal-title"
    >
      <Box sx={modalStyle}>
        <Typography id="grading-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
          Review
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 1,
            maxWidth: '100%',
            overflowX: 'auto',
            alignItems: 'center',
            justifyContent: 'start'
          }}
        >
          <Typography variant="caption" color="error" sx={{ width: '100%', marginBottom: 1 }}>Incorrect Answers</Typography>
          {wrongAnswers.map((index, idx) => (
            <Button key={idx} onClick={()=>goto(index)} variant="contained" color="error" startIcon={<Close />} size="small">
              Q{index + 1}
            </Button>
          ))}
          <Typography variant="caption" color="success" sx={{ width: '100%', marginTop: 2, marginBottom: 1 }}>Correct Answers</Typography>
          {correctAnswers.map((index, idx) => (
            <Button key={idx} onClick={()=>goto(index)} variant="contained" color="success" startIcon={<Check />} size="small">
              Q{index + 1}
            </Button>
          ))}
        </Box>
      </Box>
    </Modal>
    <Modal
      open={toggleFeedbackw}
      onClose={toggleFeedback}
      aria-labelledby="grading-modal-title"
    >
      <Box sx={modalStyle}>
        <iframe style={{position:"relative","left":"-40px",width:"calc(100% + 95px)"}} src="https://docs.google.com/forms/d/e/1FAIpQLScWwmyIXSpFf8oYTG5V74c-01dw0vdGno0a7KahvrPP11WmBQ/viewform?embedded=true" width="400" height="382" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
      </Box>
    </Modal>

<Box sx={{ display: 'flex', gap: 2 , margin:5 }}>
<Button variant="outline" onClick={toggle} startIcon={<Tune />} > </Button>
<Button variant="outline" onClick={toggleGrading} startIcon={<Grading />} > </Button>
<Button variant="outline" onClick={toggleFeedback} startIcon={<Feedback />} > </Button>
   </Box>


    </Box>
  );
};


export default Cards;
