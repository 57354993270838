import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Cards from './components/Cards';

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/cards" element={<Cards />} />
    </Routes>
  );
};

export default App;
